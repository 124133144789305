import {
  DataGridPro,
  type GridCellParams,
  type GridColDef,
  type GridRowParams,
  GridRowsProp,
  type MuiEvent,
  getGridStringOperators,
} from "@mui/x-data-grid-pro"
import { titleCase, upperCaseFirst } from "change-case-all"
import { useCallback } from "react"
import { Link } from "react-router-dom"
import { HeroIcon } from "../../components/hero-icon"
import { StudyPriorityChip } from "../../components/patients/study-priority-chip"
import { StudyScreenStatusChip } from "../../components/patients/study-screen-status-chip"
import { StudyStatusChip } from "../../components/patients/study-status-chip"
import { friendlyRelative } from "../../shared/friendly-dates"
import { snakeToTitle } from "../../shared/text-utils"

interface Props {
  patientId: string
  screens: never[]
  handleScreenNavigate
  handleScreenStart
}

export function ScreensTable(props: Props) {
  const columnDefaults: Partial<GridColDef> = {
    width: 150,
    headerClassName: "bg-gray-100 text-gray-500",
    display: "flex",
  }
  const columns: GridColDef[] = [
    {
      field: "study",
      headerName: "Study",
      ...columnDefaults,
      width: 225,
      renderCell: (params) =>
        renderCellStudy(
          params,
          props.handleScreenNavigate,
          props.handleScreenStart,
        ),
    },
    {
      field: "assigned_to",
      headerName: "Assigned",
      ...columnDefaults,
      width: 225,
      renderCell: (params) => renderCellAssigned(params),
    },
    {
      field: "last_updated",
      headerName: "Last Updated",
      ...columnDefaults,
      valueGetter: (value, row, column, apiRef) => row.updated_at,
      renderCell: renderCellLastUpdated,
    },
    {
      field: "indications",
      headerName: "Indications",
      ...columnDefaults,
      valueGetter: (value, row, column, apiRef) =>
        row.study?.indications?.map((indication) => indication.name).join(", "),
      renderCell: renderCellIndications,
    },
    {
      field: "study_status",
      headerName: "Study Status",
      ...columnDefaults,
      width: 175,
      valueGetter: (value, row, column, apiRef) => row?.status,
      renderCell: renderCellStudyStatus,
    },
    {
      field: "actions",
      headerName: "Actions",
      ...columnDefaults,
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        renderCellActions(
          params,
          props.handleScreenNavigate,
          props.handleScreenStart,
        ),
    },
  ]

  return (
    <div className="flex h-screen">
      <div className="flex-grow">
        <DataGridPro
          rows={props.screens}
          columns={columns}
          disableDensitySelector={true}
          onCellDoubleClick={(
            params: GridCellParams,
            event: MuiEvent<MouseEvent>,
          ) => {
            event.defaultMuiPrevented = true
            params.row.screen_id
              ? props.handleScreenNavigate(
                  params.row.screen_id,
                  params.row.study.abbreviation,
                )
              : props.handleScreenStart(params.row.study_id)
          }}
          initialState={{
            filter: {
              filterModel: {
                items: [
                  {
                    field: "study_status",
                    operator: "isAnyOf",
                    value: ["enrolling", "active"],
                  },
                ],
              },
            },
            columns: {
              columnVisibilityModel: {
                // Hide these columns by default, the other columns will remain visible
                indications: false,
              },
            },
            density: "comfortable", // contact
          }}
          sx={{
            "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            ".dark & .MuiDataGrid-columnHeader": {
              backgroundColor: "rgb(55 65 81)",
              color: "#FFFFFF",
            },
          }}
        />
      </div>
    </div>
  )
}

function renderCellStudyStatus(props: GridCellParams) {
  return <StudyStatusChip text={props.value} status={props.value} />
}

function renderCellAssigned(props: GridCellParams) {
  return (
    <div className="text-sm font-medium">{props.value?.name || "None"}</div>
  )
}

function renderCellIndications(props: GridCellParams) {
  return (
    <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md">
      {props.value || "Unknown"}
    </span>
  )
}

function renderCellLastUpdated(props: GridCellParams) {
  if (!props.row.screen_id) return <div className="text-xs">Not Started</div>
  return (
    <div
      className="text-xs whitespace-nowrap dark:text-gray-40"
      title={props.row.updated_at}
    >
      <p className="">
        {upperCaseFirst(friendlyRelative(props.row.updated_at))}
      </p>
      {props.row.updated_by && <p className="">{props.row.updated_by.name}</p>}
    </div>
  )
}

function renderCellStudy(
  params: GridCellParams,
  handleScreenNavigate,
  handleScreenStart,
) {
  return (
    <Link
      to={"#"}
      className="text-sm font-medium hover:cursor-pointer w-full flex-col"
      onClick={() =>
        params.row?.screen_id
          ? handleScreenNavigate(
              params.row.screen_id,
              params.row.screen?.abbreviation,
            )
          : handleScreenStart(params.row.study_id)
      }
    >
      <StudyScreenStatusChip
        text={params.row.study?.abbreviation?.substring(0, 13)}
        status={params.row?.screen_status}
        tooltipPlacement="right"
      />
    </Link>
  )
}

function renderCellActions(
  props: GridCellParams,
  handleScreenNavigate,
  handleScreenStart,
) {
  if (!props.row?.study_id) return <>...</>
  return (
    <button
      type="button"
      onClick={() =>
        props.row?.screen_id
          ? handleScreenNavigate(props.row.screen_id, props.row.abbreviation)
          : handleScreenStart(props.row.study_id)
      }
      className="bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-white text-xs font-medium mr-6 px-2.5 py-1.5 rounded-md flex items-center justify-between"
    >
      {props.row?.screen_id ? "Continue" : "Start"}
      <HeroIcon icon="ArrowCircleRightIcon" className="ml-1 w-5 h-5 flex" />
    </button>
  )
}

function getActions(
  params: GridRowParams,
  handleScreenNavigate,
  handleScreenStart,
) {
  return [
    <button
      key={params.id}
      type="button"
      onClick={() => {}}
      className="bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-white text-xs font-medium mr-6 px-2.5 py-1.5 rounded-md flex items-center justify-between"
    >
      Continue Screen
      <HeroIcon icon="ArrowCircleRightIcon" className="ml-1 w-5 h-5 flex" />
    </button>,
  ]
}
