/* This example requires Tailwind CSS v2.0+ */
import { useAuth0 } from "@auth0/auth0-react"
import {
  CalendarIcon,
  ChartPieIcon,
  ClipboardIcon,
  DocumentTextIcon,
  LibraryIcon,
  OfficeBuildingIcon,
  PlusCircleIcon,
  UsersIcon,
} from "@heroicons/react/solid"
import { useContext, useEffect, useState } from "react"
import {
  Link,
  useMatch,
  useResolvedPath,
  useSearchParams,
} from "react-router-dom"
import { AuthorizationContext } from "../shared/authorization-provider"
import { event } from "../shared/event"
import { useCreate, useShow } from "../shared/use-rest"
import { HeroIcon } from "./hero-icon"
import { isAdmin, managerOrQaUser } from "./protected-admin"
import { Snow } from "./snow"

interface Props {
  expanded?: boolean
}

export default function Sidebar(props: Props) {
  const [expanded, setExpanded] = useState(true)
  const [referralCount, setReferralCount] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [referralBookmarkId, setReferralBookmarkId] = useState("")
  const { getAccessTokenSilently } = useAuth0()
  const {
    currentUser,
    isLoading: isLoadingCurrentUser,
    practiceId,
  } = useContext(AuthorizationContext)

  useEffect(() => {
    if (!practiceId || !getAccessTokenSilently) return
    const createReferralBookmark = async () => {
      const response = await useCreate(
        "filter_and_sort_bookmark",
        {
          filter_and_sort: {
            filtering: {
              filters: [
                {
                  field: "status",
                  operator_value: "equals",
                  value: "provider_referral",
                },
              ],
            },
            practice_id: practiceId,
            search: "",
            sorting: [],
          },
        },
        getAccessTokenSilently,
      )
      setReferralBookmarkId(response?.data?.data?.id)
    }

    createReferralBookmark()

    return () => {}
  }, [practiceId, getAccessTokenSilently])

  const { data: referralQueryCount } = useShow(
    referralBookmarkId && practiceId && "patients_filter_and_sort_count",
    referralBookmarkId,
    `practice_id=${practiceId}`,
  )

  if (isLoadingCurrentUser) return <></>
  const navigation = [
    {
      name: "Directory",
      icon: UsersIcon,
      requiresAdmin: false,
      href: "/patients",
      // children: [
      //   // { name: 'Directory', href: '/patients' },
      //   // { name: 'Kanban', href: '/patient_cards' },
      //   // { name: 'Appointments', href: '/appointments' },
      //   // { name: 'Quick Add', href: '/patients?modal=new' },
      //   // { name: 'Uploader', href: '/uploads' },
      // ],
    },
    {
      name: "Referrals",
      icon: PlusCircleIcon,
      requiresAdmin: false,
      href: "/referrals",
      badge: referralQueryCount?.count?.toString(),
    },
    {
      name: "Quality Assurance",
      icon: ClipboardIcon,
      requiresAdmin: false,
      requiresQa: true,
      href: "/quality-assurance",
    },
    // {
    //   name: 'Kanban',
    //   icon: CollectionIcon,
    //   requiresAdmin: false,
    //   href: '/kanban',
    //   // children: [
    //   //   // { name: 'Directory', href: '/patients' },
    //   //   // { name: 'Kanban', href: '/patient_cards' },
    //   //   // { name: 'Appointments', href: '/appointments' },
    //   //   // { name: 'Quick Add', href: '/patients?modal=new' },
    //   //   // { name: 'Uploader', href: '/uploads' },
    //   // ],
    // },
    {
      name: "Dashboard",
      icon: ChartPieIcon,
      requiresAdmin: false,
      href: "/dashboard",
    },
    {
      name: "Calendar",
      icon: CalendarIcon,
      requiresAdmin: false,
      href: "/calendar",
    },
    {
      name: "Studies",
      icon: DocumentTextIcon,
      requiresAdmin: false,
      href: "/studies",
      // children: [
      //   // { name: 'Available Studies', href: '/studies' },
      // ],
    },
    {
      name: "Sponsors",
      icon: OfficeBuildingIcon,
      requiresAdmin: false,
      href: "/sponsors",
      // children: [
      //   // { name: 'List', href: '/sponsors' },
      // ],
    },
    // {
    //   name: 'Notifications',
    //   icon: InboxInIcon,
    //   current: false,
    //   href: '/notifications',
    //   rightContent: <Badge>1</Badge>
    // },
    // {
    //   name: 'Tasks',
    //   icon: CheckCircleIcon,
    //   current: false,
    //   href: '/tasks',
    //   // children: [
    //   //   // { name: 'View All', href: '/tasks' },
    //   //   // { name: 'Quick Add', href: '/tasks?modal=new' },
    //   // ],
    // },
    {
      name: "Admin",
      icon: LibraryIcon,
      requiresAdmin: true,
      href: "/admin",
      // children: [
      //   // { name: 'Practices', href: '/admin/practices' },
      //   // { name: 'Sponsors', href: '/admin/sponsors' },
      //   // { name: 'Studies', href: '/admin/studies' },
      //   // { name: 'Conditions', href: '/admin/conditions' },
      //   // { name: 'Medications', href: '/admin/medications' },
      //   // { name: 'Users', href: '/admin/users' },
      //   // { name: 'Comments', href: '/admin/comments' },
      //   // { name: 'Events', href: '/admin/events' },
      // ],
    },
    // {
    //   name: 'Help',
    //   icon: SupportIcon,
    //   current: false,
    //   children: [
    //     { name: 'Live Chat', href: '/chat' },
    //     { name: 'Give Feedback', href: '/feedback' },
    //   ],
    // },
  ]
  // TODO: function that loops through nav and assigns open Boolean to top level nav, and current boolean to children

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  const clickEvent = async (name) => {
    await event({
      eventName: "Click",
      meta: { element: `Nav${name.replace(/\s/g, "")}` },
      getAccessTokenSilently,
    })
  }
  const handleNavExpand = () => {
    expanded ? clickEvent("Collapse") : clickEvent("Expand")
    setExpanded(!expanded)
  }

  return (
    <div
      className={classNames(
        expanded ? "w-36" : "w-[4rem]",
        "hidden md:flex md:flex-row md:inset-y-0 transition-all duration-500",
      )}
    >
      <div
        className={classNames(
          expanded ? "" : "",
          "dark:border-gray-700 flex flex-col flex-grow pt-2 pb-4 no-scrollbar",
        )}
      >
        <div className="flex-grow flex flex-col">
          <div className="relative h-full">
            <div
              className={`flex hover:cursor-pointer ${
                expanded ? "justify-end mr-2" : "justify-center "
              }`}
              onClick={() => handleNavExpand()}
              onKeyUp={() => handleNavExpand()}
            >
              <HeroIcon
                icon={expanded ? "ChevronLeftIcon" : "ChevronRightIcon"}
                className={`w-6 h-6 ${
                  expanded ? "text-gray-900" : "text-gray-900"
                }`}
              />
            </div>
            <Snow />
            <nav className="flex-1 px-2 space-y-1" aria-label="Sidebar">
              {navigation.map((item) => (
                <div
                  className={
                    (item.requiresAdmin && !isAdmin(currentUser)) ||
                    (item?.requiresQa && !managerOrQaUser(currentUser))
                      ? "hidden"
                      : ""
                  }
                  key={item.name}
                >
                  {/* TODO: refactor useMatch and useResolvePath to "use" their equivalent non-hook versions. */}
                  {/* https://reactrouter.com/docs/en/v6/utils/resolve-path#resolvepath */}
                  <Link
                    title={item.name}
                    onClick={() => clickEvent(item.name)}
                    to={item.href}
                    className={classNames(
                      useMatch({ path: useResolvedPath(item.href).pathname })
                        ? "bg-white"
                        : "bg-gray-100  hover:bg-gray-50 hover:text-gray-900",
                      "text-gray-900 dark:text-gray-200 group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md justify-center dark:bg-gray-800 dark:hover:bg-gray-500",
                    )}
                  >
                    <item.icon
                      // title={item.name}
                      className={classNames(
                        useMatch({
                          path: useResolvedPath(item.href).pathname,
                        })
                          ? "text-gray-900"
                          : "text-gray-900 group-hover:text-gray-200",
                        "flex-shrink-0 h-4 w-4 dark:text-gray-200 dark:group-hover:text-gray-200",
                      )}
                      aria-hidden="true"
                    />
                    <div
                      className={`flex items-center justify-between transition-all ${
                        expanded ? "ml-2 flex-1" : "text-white hidden"
                      }`}
                    >
                      {item.name}
                      {item.badge && (
                        <div className="relative inline-flex items-center justify-center ml-2 py-1 px-1.5 bg-red-600 text-white text-xs rounded-full w-4 h-4">
                          {item.badge}
                        </div>
                      )}
                    </div>
                    <div className="flex ml-2">{item.rightContent}</div>
                  </Link>
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}
