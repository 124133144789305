import { useDelete } from '../shared/use-rest'
import { Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router-dom'
import { event } from '../shared/event'
import { SidebarComments } from '../components/patients/sidebar-comments'
import { useAuth0 } from '@auth0/auth0-react'
import { PatientInfoSidebarAppointments } from '../components/patients/patient-info-sidebar-appointments'
import { PatientInfoSidebarContacts } from '../components/patients/patient-info-sidebar-contacts'
import { KeyedMutator } from 'swr/dist/types'

interface Props {
  setNewAppointmentModalOpen: Dispatch<SetStateAction<boolean>>
  setContactModalOpen: Dispatch<SetStateAction<boolean>>
  contactModalEditingId: string
  setContactModalEditingId: Dispatch<SetStateAction<string>>
  setContactDispositionOpen: Dispatch<SetStateAction<boolean>>
  contactDispositionOpen: boolean
  mutateContacts: KeyedMutator<any>
  patient: {}
  mutatePatient: KeyedMutator<any>
  patientAppointments: []
  contacts: []
  mutatePatientAppointments: KeyedMutator<any>
}

export function PatientInfoSidebar(props: Props) {
  let { patientId } = useParams()
  const { getAccessTokenSilently } = useAuth0()

  const clickEvent = (name) => {
    event({eventName: 'Click', meta: {element: `${name.replace(/\s/g, '')}`}, getAccessTokenSilently})
  }

  const deleteAppointment = async (id) => {
    await useDelete('patient_appointments', id, getAccessTokenSilently)
    props.mutatePatientAppointments()
  }

  return (
    <>
      {/* { isLoading ? 'Loading..' : '' }
      { isError ? 'Failed to load' : '' } */}

      <PatientInfoSidebarAppointments
        patientId={patientId}
        patientAppointments={props.patientAppointments}
        setNewAppointmentModalOpen={props.setNewAppointmentModalOpen}
      />
      <PatientInfoSidebarContacts
        patientId={patientId}
        patient={props.patient}
        contacts={props.contacts}
        setContactModalOpen={props.setContactModalOpen}
        contactModalEditingId={props.contactModalEditingId}
        setContactModalEditingId={props.setContactModalEditingId}
        contactDispositionOpen={props.contactDispositionOpen}
        setContactDispositionOpen={props.setContactDispositionOpen}
        mutateContacts={props.mutateContacts}
      />
      <SidebarComments
        patientId={patientId}
      />
    </>
  );
}
